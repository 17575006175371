import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Row, Col, Alert } from 'react-bootstrap'
import { cap,pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import '../style/montserrat.scss'
import logoig from '../assets/img/dinny/logoig.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import pathputih1 from '../assets/img/ninda/pathputih1.svg'
import pathblue1 from '../assets/img/ninda/pathblue1.svg'
import covid from '../assets/img/ninda/covid.svg'

import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import post from "../params/post"
let cmain = '#5e7561'
let orange = '#B99225'
let black = 'rgb(38,38,38)'

let id = 'melisa-andhika'
let inisial_co = 'Andhika'
let inisial_ce = 'Melisa'
let lengkap_co = 'Andhika Dwi Putra'
let lengkap_ce = 'Melisa Anzani'
let bapak_co = 'Bpk. Syafrul Nisan'
let ibu_co = 'Ibu Endah Dwi Arini'
let bapak_ce = "Bpk. Alm Maryuli "
let ibu_ce = "Ibu Mulyani.O"
let ig_co = "-"
let ig_ce = "melisaazani_03"

let foto_ce = "https://i.ibb.co/WHrgx9M/Individu-2.jpg"
let foto_co = "https://i.ibb.co/wRYZWc4/Individu-1.jpg"
let waktunikah = "01/31/2021"

let modal = pw(id,"modal.jpg")
let openlogo = pw(id,"logo.png")

let gmaps = "https://goo.gl/maps/jHn7UUPnjfGE3D9m8"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=NWQ3aHJmc3UxNnFqdnFnZ21jNzRpZGZjNHEgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'

let slide = ["20200720-164053 (1).JPG",
"820E3A54-D71F-4321-9D66-86B03E4BB1E0.JPG",
"EBC3FEF8-D335-4C78-83D8-2A643DF5E942.JPG",
"IMG-20190402-WA0016.JPG",
"IMG-6988.JPG",
]
let blue = '#D16BFF'

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        var countDownDate = new Date(waktunikah).getTime();

        // Update the count down every 1 second
        var x = setInterval(() => {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })


        }, 1000);

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id,"music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                              )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
        let slider = []
        slide.map(v => {
            slider.push(gambar(pw(id,v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=800&h=520'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5'>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='position-relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <div className="position-absolute" style={{ left: 0, bottom: '10%', width: '100vw' }}>
                                <Item>
                                    <Col xs={12} md={4} className='m-2 m-md-0 '>
                                        <img className='img-fluid w-100 p-2'
                                            src={openlogo} data-aos="fade-left" data-aos="fade-left" />
                                    </Col>
                                </Item>
                                <Item>
                                    {
                                        <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`}
                                            style={{ marginTop: '0' }} data-aos="fade-right">
                                            Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                    }</Item>
                                <Row className='justify-content-center'>
                                    <div onClick={() => { this.play() }}
                                        // data-aos="fade-left"
                                        className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                        style={{ marginTop: 0, color: 'white' }}>
                                        Open Invitation
                            </div>
                                </Row>

                            </div>
                            <img className="w-100 img-fluid position-absolute" style={{ left: 0, bottom: 0 }}
                                src={pw(id,"path.svg")}
                            />

                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'white' }}>
                                <Container fluid style={{
                                    backgroundColor: blue
                                }}>
                                    <Container className="dinny px-3 py-5 " >
                                        <Item>

                                            <img src={'https://i.ibb.co/9gFs70g/Bird.png'} style={{ width: '50px', height: '50px' }} />

                                            <p className="text-center  px-3 py-3 w-100 text-center"
                                                style={{
                                                    color: 'white',
                                                    fontFamily: 'Montserrat,Helvetica,Arial,Lucida,sans-serif',
                                                    fontSize: '14px'

                                                }} data-aos="fade-left">
                                               “Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan
                                                    untukmu isteri-isteri dari jenismu sendiri, supaya kamu
                                                    cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya
                                                    diantaramu rasa kasih dan sayang. Sesungguhnya pada yang
                                                    demikian itu benar-benar terdapat tanda-tanda bagi kaum
                                              yang berfikir.” <br />
                                                    <i>(QS. Ar-Rum: 21)
                                                  </i>
                                        </p>
                                        </Item>

                                    </Container>
                                </Container>
                                <Container fluid style={{ backgroundColor: blue, padding: 0, position: 'relative' }}>
                                    <img src={pathputih1} className="position-absolute"
                                        style={{ bottom: 0, left: 0, width: '110vw' }} />
                                </Container>
                                <Container style={{ color: 'black' }} className="py-5">
                                    <Item>
                                        <Col md={5}>
                                            <h1 className="w-100 text-left"
                                                style={{
                                                    fontFamily: 'Parisienne, cursive', color: blue,
                                                    fontSize: '36px'
                                                }} data-aos="fade-left">
                                                {lengkap_ce}
                                            </h1>
                                            <img src={logoig} className='btn p-0 my-3'
                                                data-aos="fade-right"
                                                onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                            <p className="w-100 text-left" style={{ fontSize: '16px' }}>
                                                <b>Putri ketiga dari </b><br />
                                                {bapak_ce}<br />
                                                & {ibu_ce}
                                            </p>
                                        </Col>
                                        <Col md={2} >
                                            <Row className="h-100">
                                                <h1 className="w-100 text-center my-auto"
                                                    style={{
                                                        fontFamily: 'Parisienne, cursive',
                                                        color: blue, fontSize: '72px'
                                                    }} data-aos="fade-left">
                                                    &
                                            </h1>
                                            </Row>
                                        </Col>
                                        <Col md={5}>
                                            <h1 className="w-100 text-right"
                                                data-aos="fade-right"
                                                style={{
                                                    fontFamily: 'Parisienne, cursive', color: blue,
                                                    fontSize: '36px'
                                                }}>
                                                {lengkap_co}
                                            </h1>

                                            <div className="text-right">


                                                <img src={logoig} className='btn p-0 my-3'
                                                    onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />
                                            </div>
                                            <p className="w-100 text-right" data-aos="fade-left"
                                                style={{ fontSize: '16px' }}>
                                                <b>Putra kedua dari </b><br />{bapak_co}<br />
                                                & {ibu_co}
                                            </p>
                                        </Col>
                                    </Item>
                                </Container>

                                <Container fluid className="text-center px-4 dinny py-3"
                                    style={{ color: 'black' }} >
                                    <Item>
                                        <p style={{ fontSize: '16px' }} data-aos="zoom-in">
                                            Yang Insyaallah akan dilaksanakan pada:
                                        </p>
                                    </Item>

                                    <Item>
                                        <Col md={5}>
                                            <p className="w-100 text-center" style={{ color: 'black', fontSize: '18px' }} data-aos="fade-right">
                                                <b style={{ color: blue, fontSize: '36px',fontFamily:'Sacramento, cursive' }}> 
                                                <img src={'https://www.flaticon.com/svg/static/icons/svg/2905/2905065.svg'}
                                                    width="50px" height="50px" /><br/>Akad Nikah </b><br />
                                                <span style={{ fontSize: '16px' }}>
                                
                                                    Minggu, 31 Januari 2021<br />
                                                    09.00 - 10.00 WIB
                                             </span>

                                            </p>
                                        </Col>
                                        <Col md={5} className="pt-3 pt-md-0">
                                            <p className=" w-100 text-center" style={{ color: 'black', fontSize: '18px' }} data-aos="fade-right">
                                                <b style={{ color: blue, fontSize: '36px',fontFamily:'Sacramento, cursive' }} 
                                                >
                                                    <img src={'https://www.flaticon.com/svg/static/icons/svg/703/703213.svg'}
                                                        width="50px" height="50px" /><br />
                                                    Resepsi</b><br />
                                                <span style={{ fontSize: '16px' }}>
                                                Minggu, 31 Januari 2021<br />
                                                Jam pertama : 11.00 - 13.30 WIB <br/>
                                                Jam kedua : 14.00 - 16.00 WIB
                                             </span>
                                            </p>
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p style={{ fontSize: '16px' }} data-aos="fade--left">
                                            
                                            <span >
                                            Jl.Ikan Hias Gg. Sarmili RT 002 RW 06 No.55 L kec. Kramat Jati Kel. Batu Ampar Jakarta Timur
                                        </span>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p style={{ fontSize: '16px', fontStyle: 'italic', }}>Undangan Hanya untuk 2 orang</p>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${blue}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            data-aos="fade-right"
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: blue }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${blue}`,
                                                borderRadius: '10px'
                                            }}
                                            data-aos="fade-left"

                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: blue }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>

                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={pw(id,"covid.svg")} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>

                                <Container id='sectiongold57'>
                                    <h1 className="w-100 text-center" style={{
                                        fontFamily: '"Marck Script", cursive',
                                        color: blue,
                                        fontSize: '36px'
                                    }}>
                                        Save The Date
                                    </h1>
                                    <div data-aos="fade-right">

                                        <Item>
                                            <div data-aos="fade-left" data-aos-duration="1000"
                                                className='col-10 col-lg-8 kotak'
                                                style={{ backgroundColor: blue }}>
                                                <Item>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {days}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Days
                                                        </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {hours}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Hours
                                                    </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div >
                                                                {minutes}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Mins
                                                    </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot' >:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {seconds}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Secs
                      </span>
                                                        </Item>
                                                    </div>
                                                </Item>

                                            </div>
                                        </Item>
                                    </div></Container>
                                


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} data-aos="fade-right" />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3' data-aos="fade-left">

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: blue }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 ' style={{ fontSize: '16px' }}>
                                                        Wahai manusia! Bertakwalah kepada Tuhanmu yang telah menciptakan kamu dari diri yang satu (Adam), dan (Allah) menciptakan pasangannya (Hawa) dari (diri)-nya; dan dari keduanya Allah memperkembangbiakkan laki-laki dan perempuan yang banyak. Bertakwalah kepada Allah yang dengan nama-Nya kamu saling meminta, dan (peliharalah) hubungan kekeluargaan. Sesungguhnya Allah selalu menjaga dan mengawasimu.
                                                        <br/>
                                                        <br/>
                                                        (Q.S An-Nisa: 1)
                                                        </p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container fluid style={{ backgroundColor: blue }} className="pb-3">
                                    <Container id='sectiongold58' >

                                        <div className='pt-3 mt-4 mt-lg-5 mb-lg-3' data-aos="fade-right">
                                            <Item>
                                                <Col xs={4} lg={2}>
                                                    <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                                </Col>
                                            </Item>
                                            <Item>
                                                <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                    <Item>
                                                        <h1 className="w-100 text-center" style={{
                                                            fontFamily: '"Marck Script", cursive',
                                                            color: blue
                                                        }}>
                                                            Send Your Wishes
</h1>
                                                    </Item>
                                                    <Item>
                                                        <form className="col-12 w-100">
                                                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                            <Item>
                                                                <Col xs={12} className=''>
                                                                    {
                                                                        submitted == true ? (
                                                                            <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                                Pesan anda sudah disampaikan
                                                                            </Alert>) : (submitted === false ? (
                                                                                <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                    {
                                                                                        err.map(val => {
                                                                                            return (
                                                                                                <li>{val}</li>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </Alert>
                                                                            ) : false)
                                                                    }

                                                                </Col>
                                                            </Item>
                                                            <Item>
                                                                <div className='col-6 button rounded btn'
                                                                    onClick={() => this.handleSubmit()} style={{ backgroundColor: blue, color: 'white' }} no> Kirim </div>
                                                            </Item>
                                                        </form>
                                                    </Item>
                                                </div>
                                            </Item>
                                        </div>
                                    </Container>

                                </Container>
                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

